import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import Img from "gatsby-image"
import { navigate } from "gatsby";
import { gsap } from "gsap";

import { flattenConfs, getAutoplaySpeed, getCurrentTime, getIncoming, fadeIn, fadeOut, printDatas, getFormatedDay, getDay } from "../../helpers/helpers";

import Layout from "../layout/layout"
import Seo from "../layout/seo"
import Header from "../layout/header";
import { MainTitle } from "../shared/title";
import Conf, { ConfItem, ConfRow } from "../shared/conf";
import { MarkerIco, TimeIco } from "../shared/icos";



const colors = [ '#EC672C', '#4BBECF', '#FFDD00', '#84329B', '#91C36E', '#DC2319', '#964B00'];

const getInitials = (name) => {
    return name.split(/\s/).slice(0, 2).reduce((response,word)=> response+=word.slice(0,1),'');
}

const Person = (props) => {
    const { name, color, company } = props;

    return (
        <div className={`flex items-center font-bryant`}>
            <span className={`flex-none leading-none block mr-2 p-2 rounded-full w-6.5 h-6.5 flex items-center justify-center text-2.5 text-white font-bold`} style={{backgroundColor: color}}>{ getInitials(name) }</span>
            { company ? (
                <div className={`flex-1`}>
                    <span className={`mb-1 block leading-none text-xl text-bdark-gray`}>{ name }</span>
                    <span className={`block leading-none text-sm text-bdark-gray`}>{ company }</span>
                </div>
            ) : (
                <span className={`flex-1 leading-none text-lg text-bdark-gray`}>{ name }</span>
            )}
        </div>
    )
}
Person.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    company: PropTypes.string,
}

const Visual = (props) => {
    const { conf } = props;

    return (
        <Img fluid={conf.visual.childImageSharp.fluid} />
    );
}


const Index = (props) => {

    const conferencesDays = props.pageContext.data;
    const allConfs = flattenConfs(conferencesDays);
    const currentDate = getCurrentTime(props.location.search);
    const conf = getIncoming(allConfs, currentDate);
    const mainRef = useRef();
    let title;

    if (conf) {
        title = <MainTitle content={"Conférence à Pollutec"} sub={getFormatedDay(getDay(conf))}/>
    }

    printDatas(props.location.search, conferencesDays, currentDate);

    const zoomTimer = () => {
        gsap.delayedCall(getAutoplaySpeed(props.location.search, 30), () => {
            fadeOut(mainRef.current, () => navigate(`/${props.location.search}`));
        });
    }
    useEffect(() => {
        if (conf)
            fadeIn(mainRef.current, zoomTimer);
        else
            fadeOut(mainRef.current, () => navigate(`/${props.location.search}`));
    }, []);


    return (
        <Layout>
            <Seo title="Détail conférence"/>

            <div ref={mainRef} style={{ opacity: 0, visibility: "hidden" }}>
                { conf && (
                    <>
                        <Header title={ title }/>

                        <div className={`flex flex-row space-x-17.5`}>
                            <div className={`w-1/2 pt-4`}>

                                <Conf>
                                    <ConfRow classes={`space-x-8.75 text-borange`}>
                                        <ConfItem classes={`flex-none`} picto={<TimeIco color={'#91C36E'} classes={`flex-none mr-2 w-6 h-6`}/>} text={`${conf.startHour}:${conf.startMinutes} - ${conf.endHour}:${conf.endMinutes}`}/>
                                        <ConfItem classes={`flex-1 uppercase`} picto={<MarkerIco color={'#0091DA'} classes={`flex-none mr-2 w-6 h-6`}/>} text={conf.forum}/>
                                    </ConfRow>
                                </Conf>

                                <h3 className={`mt-3 mb-6 font-medium text-12.5 text-borange leading-15`}>{ conf.title }</h3>

                                <p className={`text-2xl leading-8.5`}>{ conf.description }</p>

                                <div className={`mt-10 pt-5 pb-1.5 px-10 rounded-6.25 border-0.25 border-blight-gray divide-y divide-bxlight-gray`}>

                                    <div className={`py-5`}>
                                        <span className={`block mb-5 font-bryant uppercase tracking-0.15 text-2.5 leading-none text-bdark-gray`}>Animatrice</span>
                                        <Person name={conf.host} color={colors[0]}/>
                                    </div>

                                    <div className={`py-5`}>
                                        <span className={`block mb-1.5 font-bryant uppercase tracking-0.15 text-2.5 leading-none text-bdark-gray`}>Invités</span>
                                        <ul className={`flex flex-wrap -mx-4.25`}>
                                            {conf.speakers.map((speaker, index) => (
                                                <li className={`w-1/3 py-3.5 px-4`} key={index}>
                                                    <Person name={`${speaker.firstname} ${speaker.lastname}`} color={colors[index+1]} company={speaker.company}/>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className={`w-1/2`}>
                                <Visual conf={conf}/>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Layout>
    )
}

export default Index
